import { brand } from './common/brand'
import { footer } from './common/footer'
import { headerV2 } from './common/headerV2'

export default {
  title: 'New Homepage',
  slug: 'index',
  seo: {
    pageTitle: 'Best Resume Writing Service | Professional Resume Writers',
    description:
      'TopResume is the top resume writing service with experienced writers in virtually every industry. Contact us today!',
    openGraphTitle: null,
    openGraphDescription: null,
    openGraphImage: null,
    noIndex: false,
    doNotDeployToProduction: false,
    pageType: null,
    canonicalUrl: null,
  },
  ...headerV2,
  ...brand,
  ...footer,
}
